.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.top-wrapper{
    background-image: url("./assets/bg.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Customize your main colors in :root variables */
:root {
  --main-background-color: #040617;
  --card-background-color: rgba(230, 255, 72, 0.1);
  --card-background-lighter-color: #7A7F92;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
  background-color:var(--main-background-color);
  font-family: lores-12, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2, p{
  color: var(--main-text-color);
}

h3{
  font-weight: normal;
}

.add-button{
  position: relative;
  z-index: 3;
  padding: 10px 9px;
  border: 1px solid #e6ff48;
  background-color: rgba(230, 255, 72, 0.11);
}

.bold {
  font-weight: 700;
  font-style: normal;
}


.overlay {
  position: fixed;
  background: rgba(0,0,0,0.8);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y: scroll;
}

.overlay:after{
  content: '<- Back';
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 50px;
  color: #fff;
}

.popup-grid{
  position: relative;
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-wrap: wrap;
  max-width: 680px;
  z-index: 10;
  background: #000;;
  padding: 20px;
  padding-top: 40px;
  margin: auto;
  margin-top: 200px;
  margin-bottom: 200px;
  justify-content: center;
}

.popup-grid:before{
  content: 'Select Nft';
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}

.popup-grid img{
  width: 200px;
  height: 200px;
  margin:20px;
}

.popup-grid img:hover{
  cursor: pointer;
}

.MuiButtonBase-root.MuiButton-contained.Mui-disabled{
  background-color:#999 !important;
  color: #fff !important;
  box-shadow: none !important;
}

html, body, #root, #root>div {
  height: 100%
}

.loading-overlay {
    width: 500px;
    height: 200px;
    top: 50%;
    background: rgba(0,0,0,1);
    position: fixed;
    z-index: 3;
    /* top: 0px; */
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    text-align: center;
}

.mint-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 40px;
}

.footer-img {
  width: 100px;
}

.footer-txt {
  margin-left: 10px;
}

footer.custom-footer {
  position: fixed;
  background: #000;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  bottom: 0px;
  z-index: 1000;
}

.custom-footer img {
  width: 40px;
}

@media(max-width: 800px) {
  .mint-container{
    grid-template-columns: 1fr;
  }

  .top-wrapper {
    height: auto !important;
    min-height: 100% !important;
  }
}