.p1{
    color: black;
    text-decoration: none;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
}

p{
    color: white;
}

.align-button{
    position: absolute;
    top: 7px;
    right: 205px;
}

.sendTokenButton{
    border: 1px solid white !important;
    color: white !important;
}
.MuiButton-containedPrimary{
    padding: 0 !important;
    background-color: white !important;
    color: black !important;
    padding: 10px 30px !important;
    z-index: 1;
    font-weight: bold !important;
}